<template>
  <v-navigation-drawer
    v-model="state"
    style="background-color: #ffffff"
    app
    light
    right
    width="450px"
  >
    <v-toolbar v-if="details" color="primary" class="elevation-1">
      <v-toolbar-title
        ><span
          class=" text-uppercase font white--text font-weight-bold font-size-md"
          >Details Of {{ details.name }}</span
        ></v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click.stop="close" v-on="on" icon color="white">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
        <span class="font font-weight-medium">{{ "Close" }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-divider></v-divider>
    <v-list>
      <v-list-item v-if="details" two-line>
        <v-list-item-content>
          <v-list-item-title class="font font-weight-bold"
            >Registration Number</v-list-item-title
          >
          <v-list-item-subtitle class="font font-weight-bold">{{
            details.businessRegistrationNumber
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item v-if="details" two-line>
        <v-list-item-content>
          <v-list-item-title class="font font-weight-bold"
            >TIN</v-list-item-title
          >
          <v-list-item-subtitle class="font font-weight-bold">{{
            details.tin
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item v-if="details" two-line>
        <v-list-item-content>
          <v-list-item-title class="font font-weight-bold"
            >Business Name</v-list-item-title
          >
          <v-list-item-subtitle>{{ details.name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item v-if="details" two-line>
        <v-list-item-content>
          <v-list-item-title class="font font-weight-bold"
            >Business Email</v-list-item-title
          >
          <v-list-item-subtitle>{{ details.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item v-if="details" two-line>
        <v-list-item-content>
          <v-list-item-title class="font font-weight-bold"
            >Business Contact</v-list-item-title
          >
          <v-list-item-subtitle>{{ details.contact }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="details" two-line>
        <v-list-item-content>
          <v-list-item-title class="font font-weight-bold"
            >Business Address</v-list-item-title
          >
          <v-list-item-subtitle class="font">{{
            details.address
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item v-if="details" two-line>
        <v-list-item-content>
          <v-list-item-title class="font font-weight-bold"
            >Office Location</v-list-item-title
          >
          <v-list-item-subtitle class="font ">{{
            details.officeLocation
              ? details.officeLocation
              : "No office location"
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <template v-if="details && details.directors">
        <h3 class="px-4 py-2 font">Board of Directors</h3>
        <v-list-item two-line>
          <v-simple-table
            class="font font-weight-medium"
            style="padding: 0 !important;width: 100% !important"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left font">
                    Name
                  </th>
                  <th class="text-left font">
                    Email
                  </th>
                  <th class="text-left font">
                    Phone
                  </th>
                </tr>
              </thead>
              <tbody class="font">
                <tr v-for="(director, index) in details.directors" :key="index">
                  <td>{{ director.name }}</td>
                  <td @click="openMail(director.email)">
                    <v-chip label small class="font font-weight-bold">{{
                      director.email
                    }}</v-chip>
                  </td>
                  <td>{{ director.phone }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-list-item>
      </template>

      <h3 v-if="details && details.contactPerson" class="px-4 font py-2">
        Contact Person
      </h3>
      <v-list-item v-if="details && details.contactPerson" two-line>
        <v-simple-table
          class="ft font-weight-medium"
          style="padding: 0 !important;width: 100% !important"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left font">
                  Name
                </th>
                <th class="text-left font">
                  Email
                </th>
                <th class="text-left font">
                  Phone
                </th>
              </tr>
            </thead>
            <tbody class="font">
              <tr>
                <td>{{ details.contactPerson.name }}</td>
                <td @click="openMail(details.contactPerson.email)">
                  <v-chip label small class="font font-weight-bold">{{
                    details.contactPerson.email
                  }}</v-chip>
                </td>
                <td>{{ details.contactPerson.phone }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "DetailsDrawerDialog",
  props: {
    state: Boolean,
    title: {
      type: String,
      default: "Details"
    },
    details: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    pdf: null
  }),
  watch: {
    details(payload) {
      this.pdf = `${process.env.VUE_APP_BASE.replace("/api/v1", "")}/static/${
        payload.form3Upload
      }`;
    }
  },
  methods: {
    openMail(email) {
      this.$emit("openMail", { email });
    },
    close() {
      this.$emit("close", "details");
    }
  }
};
</script>
